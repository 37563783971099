import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {brandIds} from '../../common/brands'

import {diceTicketOverlayOpenTracking} from '../../components/ShowInfo'
import {genericItemTypes, signupSources, uiLocations} from '../../common/constants'
import {actions as gateActions} from '../../components/Gate/state'
import type {State} from '../../stateType'

import Page from '../../components/Page'
import BroadcastData from '../../components/BroadcastData'
import Grid, {GridItem} from '../../components/Grid'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Signup from '../../components/Signup'
import Modal from '../../components/Modal'
import Link from '../../components/Link'

import styles from './WorldTour.less'
import HeroPanel from '../../components/HeroPanel'

export default ({homepage}) => {
  const worldTourShows = homepage.collections[0].items
  return (
    <Page
      title='Boiler Room World Tour'
      description='Boiler Room’s biggest artists, showcased across the globe. The Boiler Room World Tour invites you to see some of the most essential artists we’ve championed over the years alongside intimate broadcasts of vital local scenes in eighteen key cities—raw, uncut and in the round.'
      header={<div />}
      brandId={brandIds.boilerroom}
      disableOptInGate={true}
      disableFooterSignup={true}
    >
      <HeroPanel homepage={homepage} />
      <Grid className={styles.WorldTourGrid} justifyContent='center' >
        {worldTourShows.map((item, index) =>
          <GridItem
            key={index}
            className={styles.WorldTourGridItem}
            responsiveWidths={{small: 8, medium: 4, large: 4, extraLarge: 4}}
          >
            <Card item={item} />
            <WorldTourCTA item={item} />
          </GridItem>)}
      </Grid>
    </Page>
  )
}

const WorldTourCTA = ({item}) => {
  const [openOverlay, setOpenOverlay] = useState(false)
  const {isLoggedIn} = useSelector((state: State) => state.auth)
  const dispatch = useDispatch()
  const {show} = item

  const handleSignupOnClick = () => {
    const openGate = () => dispatch(gateActions.openGate(signupSources.showSubscribe, uiLocations.show, show.id, genericItemTypes.show))

    if (show.signup && show.signup.requires_login && !isLoggedIn) return openGate()
    setOpenOverlay(!openOverlay)
  }

  if (show.cta_enabled && show.dice_event_id) {
    return (
      <div className={styles.CTAWrapper} onClick={diceTicketOverlayOpenTracking}>
        <Button
          className={styles.Button}
          diceEventId={show.dice_event_id}
          text='GET TICKETS'
        />
      </div>
    )
  }

  if (show.cta_enabled && show.cta_url) {
    return (
      <div className={styles.CTAWrapper}>
        <Button
          href={show.cta_url}
          text={'GET TICKETS'}
          className={styles.Button}
          target='_blank'
        />
      </div>
    )
  }

  // if the Show does not have a dice_event_id or cta_url - display the show.signup as an overlay
  if (show.signup_enabled && show.signup) {
    return (
      <div className={styles.CTAWrapper}>
        <Button
          item={item}
          className={styles.SignupLink}
          onClick={handleSignupOnClick}
          text={'SIGN UP'}
        />
        {openOverlay && <SignupOverlay item={item} openOverlay={openOverlay} setOpenOverlay={setOpenOverlay} />}
      </div>
    )
  }

  // display a GET TICKETS button as a default which links to the show/parent show page
  return (
    <div className={styles.CTAWrapper}>
      <Link internalLink={`/session/${show.slug}`} className={styles.Button}>GET TICKETS</Link>
    </div>
  )
}

const SignupOverlay = ({item, openOverlay, setOpenOverlay}) => {
  if (!item.show) return
  const {show} = item

  return (
    <Modal modalOpen={openOverlay} closeModal={() => setOpenOverlay(!openOverlay)}>
      <div className={styles.SignupWrapper}>
        <div className={styles.HeaderWrapper}>
          <img className={styles.FlyerThumbnail} src={show.flyer_image} alt={`${item.title} Flyer Image`} />
          <div className={styles.TitleWrapper}>
            <h3>{item.title}</h3>
            <BroadcastData className={styles.WorldTourBroadcastData} show={show} disableCityLink={true} />
          </div>
        </div>
        <Signup
          className={styles.WorldTourShowSignup}
          signup={show.signup}
          signupSource={signupSources.worldTour}
          header={item.title}
          uiLocation={uiLocations.worldTour}
          item={show}
          favouriteItemId={show.id}
          favouriteItemType={genericItemTypes.show}
        />
      </div>
    </Modal>
  )
}
